import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { IconsPlugin, BootstrapVue } from 'bootstrap-vue'
import axios from 'axios'
import '../fa.config'
import moment from 'moment'
import momentTimezone from 'moment-timezone'
import VueTheMask from 'vue-the-mask'
import VueSilentbox from 'vue-silentbox'
import Vue2Filters from 'vue2-filters'
import VueSweetalert2 from 'vue-sweetalert2'
import titleMixin from './mixins/titleMixin'
import 'sweetalert2/dist/sweetalert2.min.css'
import uuid from './helpers/uuid'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VueCarousel from 'vue-carousel'
import VueMeta from 'vue-meta'
import VueExcelEditor from './components/excel/main.js'

Vue.prototype.moment = moment
Vue.prototype.$http = axios
Vue.prototype.uuid = uuid

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueTheMask)
Vue.use(VueSilentbox)
Vue.use(VueSweetalert2)
Vue.mixin(titleMixin)
Vue.use(VueLodash, { lodash: lodash })
Vue.use(VueCarousel)
Vue.use(VueMeta)
Vue.use(VueExcelEditor)

var Vue2FiltersConfig = {
  currency: {
    symbol: 'R$ ',
    thousandsSeparator: '.',
    decimalSeparator: ','
  }

}

Vue.use(Vue2Filters, Vue2FiltersConfig)

Vue.filter('date', value => {
  return moment(value).format('DD/MM/YYYY')
})

Vue.filter('datetime', value => {
  return momentTimezone(value + ' 03:00:00', 'America/Sao_Paulo').local().locale('pt-br').format('D [de] MMMM, YYYY h:mm ')
})

Vue.filter('date_result_list', value => {
  return momentTimezone.tz(value + ' 03:00:00', 'America/Sao_Paulo').local().locale('pt-br').format('dddd, D MMM, YYYY')
})

Vue.filter('date_result_list_wtz', value => {
  return momentTimezone.tz(value, 'America/Sao_Paulo').locale('pt-br').format('dddd, D MMM, YYYY')
})

Vue.config.productionTip = false

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 422) {
      store.commit('setErrors', error.response.data.errors)
    } else if (error.response.status === 400) {
      store.commit('setResponseMessage', { status: 'error', message: error.response.data.message })
    } else if (error.response.status === 401) {
      // store.commit('auth/setUserData', null)
      localStorage.removeItem('authToken')
      router.push({ name: 'login' })
    } else {
      return Promise.reject(error)
    }

    return error.response
  }

)

axios.interceptors.request.use(function (config) {
  config.headers.common = {
    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
    'Content-Type': 'application/json; multipart/form-data',
    'Accept': 'application/json'
  }

  return config
})

new Vue({
  router,
  store,
  created: function () {
    window.Bus = new Vue()
  },
  render: h => h(App)
}).$mount('#app')
